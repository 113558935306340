<template>
  <div>
    <b-row class="px-3">
      <p class="p4">
        <i class="fas fa-info-circle"></i>
        Para alterações do campo de pagamento de cota única do ISS para
        Florianópolis para a opção "SIM", é necessário a comprovação com a Guia
        DAM (Comprovante de pagamento) que é emitida pela prefeitura.
      </p>
    </b-row>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          CIDADE EM QUE SÃO EMITIDAS AS NOTAS FISCAIS
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.city"
            :state="validateField('city')"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          PAGO COTA ÚNICA DO ISS PARA FLORIANÓPOLIS?
        </b-col>
        <b-col class="d-flex">
          <b-form-checkbox
            class="ml-auto"
            switch
            v-model="form.paySingleQuotaValue"
            :state="validateCheckBox('paySingleQuota')"
          >
            {{ form.paySingleQuotaValue ? "Sim" : "Não" }}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir um anexo com o comprovante.'"
            :state="validateFieldFile('files')"
            :required="form.paySingleQuota"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";

const DefaultForm = {
  city: null,
  paySingleQuota: null,
  files: [],
  originalData: null,
};

export default {
  props: ["loadedFormData", "newRegister"],
  components: { UploadFile },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    return { formData, setForm };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
    };
  },
  methods: {
    initForm() {
      if (this.$props.loadedFormData) {
        this.form = { ...this.form, ...this.$props.loadedFormData };
      }

      this.form.originalData = { ...this.form };
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
      
      this.validateFiles();
    },
    validateCheckBox(name) {
      this.validations[name] = true;
      if (!this.formSubmited) return null;
      return true;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    validateFieldFile(name) {
      
      if (!this.form.paySingleQuotaValue) {
         this.validations[name] = true;
         return;
      }
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;      
      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    validateFiles() {
      const validation = this.form.files && this.form.files.length > 0;
      this.validations["files"] = validation;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
      this.validateFiles();
    },
    onSubmit() {   
      /* eslint-disable no-debugger */
      debugger
    /* eslint-enable no-debugger */      
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
