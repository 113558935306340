<template>
  <div class="modular-table">
    <div class="justify-content-center">
      <p class="p4">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </div>
    <div v-show="!this.loading" class="my-4">
      <div
        class="mb-5"
        v-for="(item, index) in this.issInformation"
        :key="index"
      >
        <b-card border-variant="light" class="custom-card">
          <b-row>
            <b-col class="justify-content-start">
              CIDADE EM QUE SÃO EMITIDAS AS NOTAS FISCAIS
            </b-col>
            <b-col class="justify-content-end">
              <h5>{{ item.city }}</h5>
            </b-col>
          </b-row>

          <b-row v-if="item.cityId == getFlorianopolisId()">
            <b-col class="justify-content-start">
              PAGA COTA ÚNICA DO ISS PARA FLORIANÓPOLIS?
            </b-col>
            <b-col class="justify-content-end">
              <SingleCotaSwitch :active="item.paySingleQuotaValue" />
            </b-col>
          </b-row>
          <p
            class="info-iss"
            v-if="
              item.cityId == getFlorianopolisId() && !item.paySingleQuotaValue
            "
          >
            Desconto de 2% na produção mensal.
          </p>
          <p v-if="item.cityId != getFlorianopolisId()" class="info-iss">
            Para notas fiscais que não são emitidas em Florianópolis, o
            pagamento do ISS é de responsabilidade do cooperado.
          </p>
        </b-card>
      </div>
    </div>
    <div v-show="this.loading" class="my-4">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>

    <ISSInformationAlterModal
      @issInfoUpdated="this.getISSInformation"
      :loadedFormData="this.florianopolisFormData"
    />
    <div class="mt-5" v-show="this.florianopolisFormData">
      <b-button
        :disabled="!this.florianopolisFormData"
        class="my-3 mr-3"
        variant="primary"
        v-b-modal:iss-alter-modal
      >
        Alterar informações de ISS
      </b-button>
    </div>
  </div>
</template>

<script>
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import { getISSInformation } from "../../../../services/tax-license/taxLicenseService";
import ISSInformationAlterModal from "../forms/iss-information/ISSInformationAlterModal.vue";
import { CITIES } from "../../../../static-data/IssInformation";

const tableFields = [
  {
    key: "city",
    label: "CIDADE QUE SÃO EMITIDAS AS NOTAS FISCAIS",
    tdClass: "text-right",
  },
  {
    key: "paySingleQuota",
    label: "",
    tdClass: "text-right",
  },
];

const SingleCotaSwitch = ({ props }) => {
  return (
    <b-form-checkbox class="ml-auto" switch v-model={props.active} disabled>
      <h5>{props.active ? "Sim" : "Não"} </h5>
    </b-form-checkbox>
  );
};

const ISSInformation = {
  name: "iss-information",
  components: { AccordionList, ISSInformationAlterModal, SingleCotaSwitch },
  setup() {
    return { tableFields };
  },
  mounted() {
    this.getISSInformation();
  },
  data() {
    return {
      issInformation: [],
      loading: true,
      florianopolisFormData: null,
      florianopolisId: CITIES.FLORIANOPOLIS,
    };
  },
  methods: {
    async getISSInformation() {
      try {
        this.loading = true;
        this.issInformation = await getISSInformation(
          this.$store.getters.getUserName
        );

        this.issInformation.map((iss) => {
          iss.paySingleQuotaValue = iss.paySingleQuota == "SIM";
          if (iss.cityId == CITIES.FLORIANOPOLIS) {
            this.florianopolisFormData = iss;
          }
        });
           
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    getFlorianopolisId: () => CITIES.FLORIANOPOLIS,
  },
};

export default ISSInformation;
</script>
<style lang="scss" scoped>
.info-iss {
  margin-left: 1.2rem;
}
</style>
