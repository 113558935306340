<template>
  <div>
    <AlterFormLayout
      modalId="iss-alter-modal"
      formTitle="Alterar informações de ISS"
      attentionModalId="iss-attention-modal"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de alteração"
      :onSubmitEvent="onSubmit"
      ref="alter-modal"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <ISSInformationForm :loadedFormData="$props.loadedFormData" />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de alteração das informações de ISS foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados alterados serão analisados e atualizados após a validação."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import ISSInformationForm from "./ISSInformationForm.vue";
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import { updateIssInformation } from "../../../../../services/tax-license/taxLicenseService";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";

export default {
  props: ["loadedFormData"],
  components: {
    AlterFormLayout,
    ISSInformationForm,
    SuccessModal,
  },
  data() {
    return {
      selectedOption: null,
      protocol: "",
    };
  },
  methods: {
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onSubmit(formData) {
      this.protocol = await updateIssInformation(formData, formData.files);
      this.$emit("issInfoUpdated");
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
      
    },
    async onDeleteAttentionModal(action) {
      if (action) {
        const alterModal = this.$refs["alter-modal"];
        alterModal.doDelete();
      }
    },
  },
};
</script>
